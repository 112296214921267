import * as styles from "../components/Login/sign-in-page.module.css";

import { Button, Input, Text, VStack, useToast } from "@chakra-ui/react";
import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";

import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";
import SetNewPasswordForm from "../components/ResetPassword/setNewPasswordForm";
import UnAuthContent from "../components/Login/UnAuthContent";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import validator from "validator";

const SetEmail: React.FC = () => {

    return (
        <Layout>
            <UnAuthContent>
                <SetNewPasswordForm></SetNewPasswordForm>
            </UnAuthContent>
        </Layout>
    );
};


export const Head = () => (
    <SEO title="CDI Health" description="Page description" />
)

export default SetEmail;
